body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTableCell-root {
  word-wrap: break-word;
}

.MuiDrawer-root a {
  white-space: normal;
}

.MuiDrawer-root a[href^="#/channels"] {
  display: none;
}

.MuiDrawer-root a[href^="#/episodes"] {
  display: none;
}

.MuiDrawer-root a[href^="#/change-password"] {
  display: none;
}

.MuiDrawer-root a[href^="#/hot-searchs-redirect"] {
  display: none;
}

.MuiDrawer-root a[href^="#/programme-overwrite-posters"] {
  display: none;
}

.MuiDrawer-root a[href^="#/horserace-data"] {
  display: none;
}

.MuiDrawer-root a[href="#/mpm"] {
  display: none;
}

.MuiDrawer-root a[href^="#/password"] {
  display: none;
}

.MuiDrawer-root a[href^="#/key-values"] {
  display: none;
}

.MuiFormHelperText-root {
  height: 1px;
}

.MuiDrawer-root a[href^="#/slots"] {
  display: none;
}

/*.MuiDrawer-root a[href^="#/push-notifications"] {
  display: none;
}*/
.MuiDrawer-root a[href^="#/programmes-autocomplete"] {
  display: none;
}

.MuiDrawer-root a[href^="#/channels-autocomplete"] {
  display: none;
}

.MuiDrawer-root a[href^="#/progammes-by-prorgamme_id"] {
  display: none;
}

.MuiDrawer-root a[href^="#/channels-by-network_code"] {
  display: none;
}

.MuiDrawer-root a[href^="#/landing"] {
  display: none;
}

.MuiDrawer-root a[href^="#/vod-live-testing-data"] {
  order: 1;
}

/*.ra-input-landscape_poster_base64{
  display: none;
}
.ra-input-portrait_poster_base64 {
  display: none;
}*/

.profile-item label span {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 0.00938em;
}

.hidden {
  display: none;
}

.profile-item {
  float: left;
  width: 100%;
}

.profile-wrapper {}

.clearfix {
  clear: both;
}

.profile-icon-wrapper {
  clear: left;
}

.mpm-list li a {
  width: 22%;
}

.mpm-list li {
  list-style: none;
}

@media only screen and (max-width: 414px) {
  .mpm-list li a {
    width: 100% !important;
  }
}

.record-search-name {
  width: 40%;
}

.record-search-order {
  width: 10%;
}

.record-search-status {
  width: 10%;
}

.record-search-action {
  width: 15%;
}

.list-page>div>div>div {
  visibility: visible !important;
}

a.mpm-list {
  display: block;
  width: 100%;
  height: 98%;
  background: grey;
}
a.mpm-list>span {
  font-size: 1.5em;
  padding: 15px;
  display: block;
}

.landcape-list {
  width: 284px;
  height: 160px;
  background: grey;
  display: block;
}

.landcape-list img {
  margin: 0;
  width: 100%;
}

.RaTreeMenu-open-41 {
  width: 220px !important;
}

.header-column {
  width: 31%;
  text-align: left;
  padding-left: 30px;
  padding-top: 13px;
  float: left;
}

.header-column.order {
  width: 7% !important;
}

.header-list {
  clear: left;
  width: 100%;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  float: left;
  padding-bottom: 12px;
}

.RaSidebar-fixed-36>div {
  overflow: auto;
  height: 100%;
}

/* 
.RaSidebar-fixed-36 {
    overflow-y: scroll!important;
    scrollbar-width: 7px!important;
    -ms-overflow-style: inherit!important;
    height: 400px!important;
}
.RaSidebar-fixed-36::-webkit-scrollbar {
  -webkit-appearance: none;
  display: block!important;
}
.RaTreeMenu-main-40{
  height: 600px;
}
.RaSidebar-fixed-36::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.RaSidebar-fixed-36::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 4px!important;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
} */

a.link-to-slot {
  color: #3f51b5;
  text-decoration: none;
  width: 327px;
  height: 185px;
  display: block;
  background-color: grey;
}

.full-width {
  width: 100%;
}

.full-width .MuiFormControl-marginDense {
  width: 100%;
}

#count-en {
  margin-bottom: 30px;
  position: relative;
  top: -10px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

#count-tc {
  position: relative;
  top: -10px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.link-to-slot-new {
  /* width: 326px;
  height: 200px; */
  width: 100%;
  height: 98%;
  display: flex;
  text-align: center;
  /* padding-top: 123px; */
  background: grey;
  justify-content: center;
  align-items: center;
}
.link-to-slot-new>img {
  height: 50%;
}

table.menu-expand-category {
  width: 100%;
}

.menu-child-empty {
  width: 44px;
}

.menu-child-category_name {
  width: 212px;
}

.menu-child-category_name_en {
  width: 208px;
}

.menu-child-category_start {
  width: 269px;
}

input[type="datetime-local"] {
  background-color: rgb(232, 232, 232);
}

.textFieldWrapText{
  word-break: break-word;
}

.textNoWrap{
  white-space: nowrap;
}

.chatroom-icon img {
  width: 150px;
  height: 150px;
}


/* Remove Code button in Rich text editor */
button[aria-label="Code"] {
  display: none;
}

div.product-dialog-description-input div#description {
  background-color: black;
}

.product-dialog-description-field, .product-dialog-description-field * {
   background-color: black;
}