.role-container label.MuiFormControlLabel-root{
    width: 25%;
    margin-left: 0;
    margin-right: 0;
    overflow-wrap: anywhere;
}

@media only screen and (max-width: 900px) {
    .role-container label.MuiFormControlLabel-root{
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .role-container label.MuiFormControlLabel-root{
        width: 100%;
    }
}
