/* ******************************
        Styles  
****************************** */
table.border-collapse{
    border-collapse:collapse !important;
}


/* ******************************
        Preview page  
****************************** */

/* hardcode col width  */
table.menu-tree-preview .MuiTableCell-sizeSmall{
    width: 20%;
}

/* make nested table fit  */
table.menu-tree-preview tr[id$="expand"]>.MuiTableCell-sizeSmall{
    padding: 0;
    width: 100%;
}


/* ******************************
        List page  
****************************** */

/* hide the column head  */
table.menu-tree-list>thead>tr>th:nth-of-type(2){
    opacity: 0;
}
table.menu-tree-list>thead>tr>th:nth-of-type(2){
    opacity: 0;
}

/* ******************************
        Edit page  
****************************** */

table.menu-tree-edit .edit-td{
    width: 100px;
}
table.menu-tree-edit .expand-td{
    width: 100px;
}
table.menu-tree-edit .normal-td{
    width: 20%;
}
table.menu-tree-edit .expand-button{
    font-size: 1.3rem !important;
}
